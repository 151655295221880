import React, {useState} from "react"
import BaseCard from './BaseCard'


const FeaturePCard = (props) => {
  const { img, title, description, currentId,id,onMouseEnter } = props
  return  <div className="mr-4.5 ml-4.5 mt-4.5 mb-4.5" onMouseEnter={() => onMouseEnter()} data-sal="slide-up" data-sal-delay="80" data-sal-easing="ease">
      <BaseCard className={`filter ${currentId  === id ? 'bg-cardbg':'bg-white bg-opacity-8'} flex items-center text-white cursor-pointer py-4 pl-4 hxl:px-5 hxl:py-4.8 drop-shadow-card hover:bg-cardbg delay-75`} >
        <img src={img} alt={title} className="h-lg w-16 hxl:w-18 hxl:h-18 hxl:mr-7" />
        <div className="ml-3 hxl:text-left hxl:ml-0" >
          <p className="text-lg font-semibold hxl:text-xbase">{title}</p>
          <p className="mt-1 text-sm hxl:mt-2 hxl:text-justify">{description}</p>
        </div>
      </BaseCard>

    </div>
}
export default FeaturePCard
