import * as React from "react"
import BaseCard from './BaseCard'
const ActiveCard = (props) => {
  const {img, title, time, type, tag } = props

  const handleType = () => {
    if (type === 0) {
      return '免费'
    } else {
      return '付费'
    }
  }

  return <div data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease">
    <BaseCard className="h-73 w-64.5 flex flex-col pb-5 bg-white text-sblack filter drop-shadow-normarl hover:drop-shadow-base active:drop-shadow-base cursor-pointer" >
        <img src={img} alt={title} className=" w-64.5 h-36 rounded-t-primary" srcSet=""/>
        <div className="pt-3 mx-5 flex-1 flex flex-col justify-between">
          <p className="text-base font-semibold ">
            {tag && (
              <span className="bg-gradient-to-r from-bright-primary to-orange text-weakpink text-xs inline-block align-top leading-5 w-9 h-5 text-center rounded-s mr-2.5">{tag}</span>
              )
            }
            {title}</p>
          <div className="">
          <p className="mt-2 text-lg">{time}</p>
            <p className="mt-1 text-lg">{handleType()}</p>
          </div>
        </div>
    </BaseCard>
  </div>
}
export default ActiveCard
