import * as React from "react"
import classnames from 'classnames'
import AddIcon from './../../images/plus-square.png'

const btnShadow = {
  filter: 'drop-shadow(2px 4px 14px rgba(255, 30, 94, 0.4))'
}

const Button = (props) => {
  const {title, type, className, size = 'default', onClick, showAdd, ...restProps} = props
  const primaryBtn = [{'bg-white filter drop-shadow-btn hover:text-hgray active:bg-ohbg': type === 'primary'},{'bg-btnDbg hover:bg-btnHbg active:bg-btnAbg': type === 'danger' },{'h-base w-primary hxl:h-14.5 hxl:w-52.5 ':size === 'large'},{'h-8 w-24 hxl:h-10 hxl:w-25.5': size === 'default'}]
  const btnStyle = classnames(primaryBtn,'select-none rounded-full flex justify-center items-center text-white cursor-pointer',className)
  const iconStyle=classnames('w-5 h-5 mr-1')

  return <div {...restProps} className={btnStyle} style={{filter: type === 'danger' ? btnShadow.filter : ''}} onClick={() => onClick()}>
    <img src={AddIcon} className={`w-4 h-4 hxl:mr-1 ${showAdd?'':'hidden'}`} /><span className={`font-semibold ${ size ==='large' ? 'text-base hxl:text-xbase':'text-lg'}`}>{title}</span>
  </div>
}

export default Button
