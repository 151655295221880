import React,{useState} from "react"
import Image from "./UI/Image"
import Button from "./UI/Button"
import {isLoggedIn} from '../service/auth'
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Error from "@mui/icons-material/Error";
import { isMobile } from "react-device-detect";
import loginSDK from "../../utils/base"
import {getLang} from '../../utils/lang'
import {loginGotin} from '../../utils/login'
import { getImageAddress } from '../../utils'

const Advisory = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleBTnClick = (type) => {
    if (isLoggedIn()) {
      // 已登录
      if (isMobile) {
        setIsOpen(true);
      } else {
        const finalUrl = `${process.env.GATSBY_CREATE_URL}?autoCreate=true&lang=${getLang()}`
        loginSDK.keepLoggedIn(finalUrl, false, true)
      }
    } else {
      // 未登录
      // if (isMobile) {
      //   setIsOpen(true);
      // } else {
        const toUrl = `${process.env.GATSBY_LOGIN_DEFAULT}?enter=${type}`
        window.gotinTrack.standaloneSendData({name: 'login.enter', params: {value: toUrl}})
        loginGotin(type)
      // }
    }
  }

  return <div className="bg-qr-main-bg hxl:bg-pc-qr-main bg-no-repeat bg-100%">
    <div className="py-16 hxl:py-32.5 text-white text-xlg hxl:text-pxlg flex flex-col  hxl:flex-row hxl:justify-between items-center hxl:px-42.5 hxl:max-w-screen-hxl hxl:mx-auto" data-sal="slide-up" data-sal-delay="80" data-sal-easing="ease">
      <div className="flex-1 flex flex-col items-center hxl:items-start">
        <p className="font-semibold text-xpbase hxl:text-psxlg">关于我们</p>
        <div className="px-8 text-base text-justify font-normal hxl:mt-9 hxl:w-925 opacity-80 hxl:px-0">
          <p className="mt-5 hxl:mt-0">北京有大科技有限公司创立于2020年，致力于打造未来的在线活动平台，通过数据分析和推荐引擎，协助主办方举办高质量的在线活动和社区。自创立起，已经累计服务客户超过100家，涵盖国内外众多知名企业，累计策划执行活动超过2,000场。其中包括世界移动通信大会、世界人工智能大会、世界互联网大会、中国互联网大会、中国卫星导航年会、中国国际软件博览会等各行业峰会。</p>
          <p className="mt-6">公司地址：北京市顺义区临空经济核心区融慧园6号楼9-91</p>
        </div>
      </div>
      <div className="text-xbase hxl:text-xlg text-center flex flex-col justify-center items-center hxl:flex-shrink">
        <Image data-track={JSON.stringify({type: 'exposure', name: 'ask.code'})} img={getImageAddress('assistant')} alt="客户经理" className="h-primary w-44.5 hxl:w-52.5 hxl:h-52.5 flex justify-center items-center bg-white bg-opacity-30 rounded-xl mt-7 hxl:mt-0"/>
        <p className="font-semibold mt-3">立即扫码咨询</p>
        <p className="font-semibold">免费领取专属解决方案</p>
      </div>
    </div>
  </div>
}
export default Advisory
