import React, { useState, useRef } from 'react'
import Container from './Container'
import ScenceCard from './UI/ScenceCard'
import { Sences } from '../../mock/data'
import '../styles/index.css'
import { useInView, InView } from 'react-intersection-observer';

const AppScence = () => {
  // for pc
  const [pcFirst, setPcFirst] = useState(Sences.slice(0, 8))
  const [pcSecond, setPcSecond] = useState(Sences.slice(8))
  // for mobile
  const [mbFirst, setMbFirst] = useState(Sences.slice(0, 5))
  const [mbSecond, setMbSecond] = useState(Sences.slice(5, 10))
  const [mbThird, setMbThird] = useState(Sences.slice(10))
  //
  const [isM, setisM] = useState(false)
  const { ref, inView } = useInView()

  return <Container title="应用场景" className="bg-gray text-sblack  items-center  select-none">
    <div className='' ref={ref}>
      <div className='hxl:hidden flex-col overflow-hidden pb-15'>
        <div className='h-46 relative mt-5' data-sal="slide-up" data-sal-delay="80" data-sal-easing="ease">
          <div className={`flex ${inView ? 'animate-rowdown' : ''} absolute`}>
            {mbFirst.map((item) => <div data-track={JSON.stringify({type: 'click', name: `application.scene${item.id}`, params: {materials_id: item.id}})} className={item.id > 1 ? 'ml-4' : ''} key={item.id}>
              <ScenceCard img={item.img} description={item.description} title={item.title} />
            </div>
            )}
            {mbFirst.map((item) => <div data-track={JSON.stringify({type: 'click', name: `application.scene${item.id}`, params: {materials_id: item.id}})} className='ml-4' key={item.id}>
              <ScenceCard img={item.img} description={item.description} title={item.title} />
            </div>
            )}
          </div>
        </div>
        <div className='h-46 relative mt-5' data-sal="slide-up" data-sal-delay="80" data-sal-easing="ease">
          <div className={`flex ${inView ? 'animate-rowdown2' : ''} absolute right-0`}>
            {mbSecond.map((item) => <div data-track={JSON.stringify({type: 'click', name: `application.scene${item.id}`, params: {materials_id: item.id}})} className={item.id > 6 ? 'ml-4' : ''} key={item.id}>
              <ScenceCard img={item.img} description={item.description} title={item.title} />
            </div>
            )}
            {mbSecond.map((item) => <div data-track={JSON.stringify({type: 'click', name: `application.scene${item.id}`, params: {materials_id: item.id}})} className='ml-4' key={item.id}>
              <ScenceCard img={item.img} description={item.description} title={item.title} />
            </div>
            )}
          </div>
        </div>
        <div className='h-46  relative mt-5' data-sal="slide-up" data-sal-delay="80" data-sal-easing="ease">
          <div className={`flex ${inView ? 'animate-rowdown3' : ''} absolute`}>
            {mbThird.map((item) => <div data-track={JSON.stringify({type: 'click', name: `application.scene${item.id}`, params: {materials_id: item.id}})} className={item.id > 11 ? 'ml-4' : ''} key={item.id}>
              <ScenceCard img={item.img} description={item.description} title={item.title} />
            </div>
            )}
            {mbThird.map((item) => <div data-track={JSON.stringify({type: 'click', name: `application.scene${item.id}`, params: {materials_id: item.id}})} className='ml-4' key={item.id}>
              <ScenceCard img={item.img} description={item.description} title={item.title} />
            </div>
            )}
          </div>
        </div>
      </div>
      <div className='hidden hxl:flex hxl:flex-col hxl:pt-10 overflow-hidden pb-15 hxl:pb-20'>
        <div className='hxl:h-55  relative' data-sal="slide-up" data-sal-delay="80" data-sal-easing="ease">
          <div className={`hxl:flex  absolute  animation-state ${inView ? 'animate-rowup' : ''} `} >
            {pcFirst.map((item) => <div data-track={JSON.stringify({type: 'click', name: `application.scene${item.id}`, params: {materials_id: item.id}})} className={item.id !== 1 ? 'hxl:ml-10' : ''} key={item.id}>
              <ScenceCard img={item.img} description={item.description} title={item.title} />
            </div>
            )}
            {pcFirst.map((item) => <div data-track={JSON.stringify({type: 'click', name: `application.scene${item.id}`, params: {materials_id: item.id}})} className='ml-10' key={item.id}>
              <ScenceCard img={item.img} description={item.description} title={item.title} />
            </div>
            )}
            {pcFirst.map((item) => <div data-track={JSON.stringify({type: 'click', name: `application.scene${item.id}`, params: {materials_id: item.id}})} className='ml-10' key={item.id}>
              <ScenceCard img={item.img} description={item.description} title={item.title} />
            </div>
            )}
            {pcFirst.map((item) => <div data-track={JSON.stringify({type: 'click', name: `application.scene${item.id}`, params: {materials_id: item.id}})} className='ml-10' key={item.id}>
              <ScenceCard img={item.img} description={item.description} title={item.title} />
            </div>
            )}
          </div>
        </div>
        <div className="hxl:h-55 relative hxl:mt-10" data-sal="slide-up" data-sal-delay="80" data-sal-easing="ease">
          <div className={`hxl:flex ${inView ? 'animate-rowup2' : ''} absolute right-52.5 animation-state`}>
            {pcSecond.map((item) => <div data-track={JSON.stringify({type: 'click', name: `application.scene${item.id}`, params: {materials_id: item.id}})} className={item.id > 1 ? 'hxl:ml-10' : ''} key={item.id}>
              <ScenceCard img={item.img} description={item.description} title={item.title} />
            </div>
            )}
            {pcSecond.map((item) => <div data-track={JSON.stringify({type: 'click', name: `application.scene${item.id}`, params: {materials_id: item.id}})} className='hxl:ml-10' key={item.id}>
              <ScenceCard img={item.img} description={item.description} title={item.title} />
            </div>
            )}
            {pcSecond.map((item) => <div data-track={JSON.stringify({type: 'click', name: `application.scene${item.id}`, params: {materials_id: item.id}})} className='hxl:ml-10' key={item.id}>
              <ScenceCard img={item.img} description={item.description} title={item.title} />
            </div>
            )}
            {pcSecond.map((item) => <div data-track={JSON.stringify({type: 'click', name: `application.scene${item.id}`, params: {materials_id: item.id}})} className='hxl:ml-10' key={item.id}>
              <ScenceCard img={item.img} description={item.description} title={item.title} />
            </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </Container>
}

export default AppScence
