import * as React from 'react'
import Container from './Container'
import ValueCard from './UI/ValueCard'
import { Values } from '../../mock/data'

const ValueGreats = () => {
  return <Container title="产品价值" className="bg-white  pb-15 hxl:pb-20 text-black" >
    <div className='hxl:flex hxl:mt-15 hxl:justify-evenly hxl:max-w-screen-hxl hxl:mx-auto'>
      {Values.map(item=><ValueCard id={item.id} img={item.img} subTitle={item.subTitle} title={item.title} description={item.description} key={item.id}/>)}
    </div>
  </Container>
}


export default ValueGreats