import * as React from "react"
import BaseCard from './BaseCard'
import User from './User'

const GreatsCard = (props) => {
  const { logo, description, user } = props
  return <div data-sal="slide-up" data-sal-delay="100" data-sal-easing="ease"><BaseCard className="w-64.5 h-72 hxl:h-72 hxl:w-72 bg-white flex flex-col justify-between text-gray-600 filter drop-shadow-normarl hover:drop-shadow-base active:drop-shadow-base cursor-pointer" >
    <div className='my-7 mx-6 hxl:mx-8 hxl:my-8'>
      <div className="flex items-center">
        <img src={logo} alt="logo" className="h-base w-10 rounded-full" />
        <p className="text-base font-semibold text-black ml-2">{user.name}</p>
      </div>
      <p className='mt-5 hxl:mt-6 text-lg hxl:text-lg text-gray text-justify  overflow-hidden overflow-ellipsis line-clamp-6 break-all '>{description}</p>
    </div>
    {/* <User user={user} /> */}
  </BaseCard>
  </div>
}
export default GreatsCard