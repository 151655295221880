
import React, {useState, useEffect,useRef} from "react"
import Header from "../Layout/Header"
import Footer from "../Layout/Footer"
import Advisory from '../components/Advisory.js'
import WordsFgreats from '../components/WordsFgreats'
import AppScence from '../components/AppScence'
import Aactivies from '../components/Aactivies'
import Feature from '../components/Feature'
import ValueGreats from '../components/ValueGreats'
import Cases from '../components/Case'

import SEO from "../components/SEO"
import toTop from '../images/toTop.svg'

import scrollTo from "gatsby-plugin-smoothscroll"
import { useWindowScroll } from 'react-use'
const Gotin = () => {
  const {x,y} = useWindowScroll()
  const handleScroolToTop = () => {
    scrollTo('#___gatsby')
  }
  return (
    <div className="flex flex-col h-screen justify-between">
      <SEO />
      <Header className="sticky gotinapp"/>
      <Cases />
      <main className="mb-auto ">
        <ValueGreats />
        <Feature />
        <Aactivies />
        <AppScence />
        <WordsFgreats />
        <Advisory />
      </main>
      <Footer />
      {y>2600 && <div className="fixed bottom-20 hxl:right-15 right-4.8 cursor-pointer" onClick={() => handleScroolToTop()}>
        <img src={toTop} alt="to top" />
      </div>}
    </div>
  )
}

export default Gotin
