import React, {useState, useEffect,useRef} from "react"
import { Router } from "@reach/router"
import Gotin from "../components/Gotin"
const IndexPage = () => {
  useEffect(async () => {
    const isMobile = !!navigator.userAgent.match(/iPhone|iPad|iPod|Android/i);
    window.gotinTrack.init({
      app_key: isMobile ? 'mobile' : 'pc',
      app_v: '1.0.0',
      project: 'Gotin',
      pageName: 'home',
      debuggerStatus: process.env.GATSBY_APP_MODE !== 'prod'
    })
    window.gotinTrack.trackPv()
  },[])
 return (
      <Gotin />
  )
}
export default IndexPage
