import * as React from "react"
import { isMobile } from "react-device-detect"

const BackupOnline = () => {
  return  <span className='text-xs text-primary mt-8 mb-5 -mx-4 hxl:text-center hxl:mt-15'>
    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011302003859" >
      <img src="https://exhibitor.gotin.online/mail/website/footer/police.svg" alt="police" className='inline-block mr-2 align-middle hxl:w-5 w-4' />
      <span className="mr-1">京公网安备 11011302003859号</span>
    </a>
    京ICP备20021230号-1
    <span className={ isMobile ? "inline-block w-full text-center" : "" }>增值电信业务经营许可证 京B2-20203369</span>
    <span className={ isMobile ? "inline-block w-full text-center" : "" }>「 有了」提供技术支持 © 2020「 有了」版权所有 </span>
  </span>
}

export default BackupOnline