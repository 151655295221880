import React, {useState,useEffect} from 'react'
import ActiveCard from './UI/ActiveCard'
import {Actives} from '../../mock/data'
import Container from './Container'
import Button from './UI/Button'
import ReactTooltip from 'react-tooltip'

import { useStaticQuery, graphql } from 'gatsby'


const Aactivies = () => {

  const [isMounted, setMount] = useState(false)

  const data = useStaticQuery(graphql`
  {
    allFile(filter: {
        extension: {eq: "png"},
        sourceInstanceName: {eq: "actives"}
      })
    {
      edges {
        node {
        childImageSharp {
            fluid(maxWidth: 300, quality: 50) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  `)

  useEffect(() => {
    setMount(true);
  }, []);

  return <Container title="发现活动" className="bg-base text-sblack flex flex-col items-center pb-15 relative bg-g-main-bg hxl:bg-pc-g-main bg-no-repeat bg-100% ">
    <div data-track={JSON.stringify({type: 'exposure', name: 'discover.event'})} className=' hxl:max-w-screen-hxl hxl:mx-auto '>
      <div className='flex flex-wrap items-center justify-center hxl:pt-5' >
        {Actives.map((item, index) =><a data-track={JSON.stringify({type: 'click', name: `item.tag === '3D' ? 'discover.3Devent' : discover.event${index+1}`, params: {materials_id: item.id, external_url: item.link}})} href={item.link}  className={`mt-5 block ${item.id !== 1 && item.id !== 5 ?' hxl:ml-10' : ''}`} key={item.id} target="_blank">
          <ActiveCard img={item.img} title={item.title} time={item.time} type={item.type} tag={item.tag}/>
        </a> )}
      </div>
      <div data-tip className='hxl:mt-18.5 flex justify-center' data-place="top" data-for="more">
        <Button data-track={JSON.stringify({type: 'click', name: 'discover.more'})} title="发现更多活动" className="mt-5 hxl:mt-0" type="danger" size="large" data-sal="slide-up" data-sal-delay="90" data-sal-easing="ease" onClick={() => {}} />
      </div>
      {isMounted &&<ReactTooltip id='more' effect='solid' className="rounded-full">
        <span>即将上线</span>
      </ReactTooltip>}
    </div>
  </Container>
}

export default Aactivies
