import React, { useState } from 'react'
import Container from './Container'
import FeatureCard from './UI/FeatureCard'
import FeaturePCard from './UI/FeaturePCard'
import { FeatureData } from '../../mock/data'
import { CSSTransition } from 'react-transition-group';
import '../styles/index.css'
import Tilt from 'react-parallax-tilt';

const duration = 300;

const Feature = () => {
  const [showImage, setShowImage] = useState(false);
  const [isShowFeature, setIsShowFeature] = useState(true)
  const [currentId, setCurrentId] = useState(1)
  const [cuurentStartFeature, setCuurentStartFeature] = useState(FeatureData.slice(0, 5))
  const [cuurentEndFeature, setCuurentEndFeature] = useState(FeatureData.slice(5))
  const [secondId, setSecondId] = useState(5)
  const [startDefault, setStartDefault] = useState(cuurentStartFeature.find(item => item.id === currentId))
  const [endDefault, setEndDefault] = useState(cuurentEndFeature.find(item => item.id === secondId))

  const handleFeatureClick = (id) => {
    setIsShowFeature(!isShowFeature)
    setCurrentId(id)
    if (currentId !== id) {
      setIsShowFeature({ isShowFeature: false })
      setIsShowFeature({ isShowFeature: true })
    }
  }

  const handleFeatureMouseEnter = (id, type) => {
    if (type === 'right') {
      const currentEle = cuurentStartFeature.find(item => item.id === id)
      setStartDefault(currentEle)
      setCurrentId(id)
      // setShowImage(!showImage)
    } else {
      const currentEle = cuurentEndFeature.find(item => item.id === id)
      setEndDefault(currentEle)
      setSecondId(id)
    }
  }

  return <Container title="特色功能及产品优势" className="bg-black text-white pb-15">
    {/* 移动端 */}
    <div className='hxl:hidden'>
      {FeatureData.map(item => <div className='mt-5' data-track={JSON.stringify({type: 'click', name: `${item.traceName}`})} key={item.id} onClick={() => handleFeatureClick(item.id)}>
        <FeatureCard id={item.id} traceName={item.traceName} img={item.img} title={item.title} description={item.description} currentId={currentId} isShowFeature={isShowFeature} content={item.content} />
      </div>)}
    </div>
    {/*pc 端 right */}
    <div className='hidden hxl:mt-15 hxl:flex hxl:justify-between hxl:items-center hxl:max-w-screen-hxl hxl:mx-auto '>
      <div className='-mr-20'  >
        {cuurentStartFeature.map(item => <FeaturePCard onMouseEnter={() => handleFeatureMouseEnter(item.id, 'right')}  key={item.id} onClick={() => handleFeatureClick(item.id)} id={item.id} img={item.img} title={item.title} description={item.description} currentId={currentId} isShowFeature={isShowFeature} content={item.content} />
        )}
      </div>
      <div className="flex-shrink-0 bg-pc-f-right-bg bg-no-repeat bg-100% hxl:w-200 hxl:h-120 hxl:relative" data-sal="zoom-in" data-sal-delay="80" data-sal-easing="ease" >
        <Tilt className="w-full h-full hxl:absolute hxl:left-18" tiltReverse={true} tiltMaxAngleX={10} tiltMaxAngleY={10}>
            {/* <CSSTransition in={showImage} timeout={duration} classNames="feature"  > */}
              <img data-track={JSON.stringify({type: 'exposure', name: `${startDefault.traceName}`})} src={startDefault.content} alt="what" className="w-full h-full"  />
            {/* </CSSTransition> */}
        </Tilt>
      </div>
    </div>
    <div className='hidden hxl:mt-44.5 hxl:flex hxl:justify-between hxl:items-center hxl:max-w-screen-hxl hxl:mx-auto '>
      <div className='hxl:flex flex-wrap hxl:justify-start -ml-20'>
        {cuurentEndFeature.map(item =>
          <FeaturePCard key={item.id} onMouseEnter={() => handleFeatureMouseEnter(item.id, 'left')} id={item.id} img={item.img} title={item.title} description={item.description} currentId={secondId} isShowFeature={isShowFeature} content={item.content} />)}
      </div>
      <div className="flex-shrink-0 bg-pc-f-left-bg bg-no-repeat bg-100% order-first hxl:w-200 hxl:h-120 hxl:relative" data-sal="zoom-in" data-sal-delay="80" data-sal-easing="ease">
        <Tilt tiltReverse={true} tiltMaxAngleX={10} tiltMaxAngleY={10} className="w-full h-full  hxl:absolute hxl:right-20">
          <img data-track={JSON.stringify({type: 'exposure', name: `${endDefault.traceName}`})} src={endDefault.content} alt="what" className="w-full h-full" />
        </Tilt>
      </div>
    </div>
  </Container>
}


export default Feature
