import * as React from "react"
import BaseCard from './BaseCard'

const ScenceCard = (props) => {
  const { img, title, description } = props
  return <BaseCard className="filter flex flex-col items-center text-gray bg-white px-5 py-5 w-sence h-sence hxl:w-52.5 hxl:h-55 hxl:py-8 hxl:px-7 cursor-pointer drop-shadow-scard hover:shadow-hbase">
    <img src={img} alt={title} className="h-15 w-15 hxl:h-18 hxl:w-18" />
    <p className="text-base  text-black mt-3 font-semibold">{title}</p>
    <p className="text-lg hxl:text-sm text-gray mt-2 text-center">{description}</p>
  </BaseCard>
}

export default ScenceCard
