import React, { useState, useEffect, useRef } from "react";
import Button from "../components/UI/Button";
import Logo from "../components/UI/Logo";
import agenda from "../images/header/my-agenda.svg";
import holder from "../images/header/card-holder.svg";
import setting from "../images/header/setting.svg";
import logoutImg from "../images/header/sign-out.svg";
import { isNotIOS } from '../../utils'
import { isLoggedIn, handleLogin,logout, isBrowser, getUserStatus, getJumpProtoIme } from "../service/auth";
import alogo from '../images/alogo.svg'
import { isMobile } from "react-device-detect";
import Snackbar from "@mui/material/Snackbar";
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Alert from "@mui/material/Alert";
import Error from "@mui/icons-material/Error";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import loginSDK from "../../utils/base";
import {getLang} from '../../utils/lang'
import {loginGotin} from '../../utils/login'

const themeSnackbar = createTheme({
  components: {
    MuiSnackbar: {
      styleOverrides: {
        root: {
          zIndex: '18000',
        },
      },
    },
  },
});

const Header = () => {
  const ref = useRef(null);
  const [isShowMenu, setisShowMenu] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const [isLogin, setisLogin] = useState(true);
  const [avatar, setAvatar] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [isExpanded, setExpanded] = useState(false);
  const [isManage, setisManage] = useState(false);
  const [menuList, setMenuList] = useState([
    {
      id: 1,
      url: `${process.env.GATSBY_LOGIN_BASE_DOMAIN}/personal/overview?lang=${getLang()}&aim=event`,
      icon: agenda,
      name: "我的活动",
      traceName: 'myevent'
    },
    {
      id: 2,
      url: `${process.env.GATSBY_LOGIN_BASE_DOMAIN}/personal/overview?lang=${getLang()}&aim=connections`,
      icon: holder,
      name: "联系人",
      traceName: 'card'
    },
    {
      id: 3,
      url: `${process.env.GATSBY_LOGIN_BASE_DOMAIN}/personal/overview?lang=${getLang()}&aim=event`,
      icon: setting,
      name: "个人信息",
      traceName: 'profile'
    },
    // {
    //   id: 4,
    //   url: "",
    //   icon: language,
    //   name: "语言",
    //   traceName: 'language',
    //   children: [
    //     {
    //       id: 1,
    //       name: "中文",
    //       checked: true,
    //     },
    //     {
    //       id: 2,
    //       name: "English",
    //       checked: false,
    //     },
    //   ],
    // },
    {
      id: 4,
      url: `${process.env.GATSBY_CREATE_URL}?lang=${getLang()}`,
      icon: "",
      name: "创建中心",
      traceName: 'creat',
    },
    {
      id: 5,
      url: "",
      icon: logoutImg,
      name: "退出",
      traceName: 'logout',
    },
  ]);
  const handleBTnClick = (type) => {
    // 已登录不区分跳转
    if (!isLogin) {
      if (!isMobile) {
        const finalUrl = `${process.env.GATSBY_CREATE_URL}?autoCreate=true&lang=${getLang()}`
        console.log(finalUrl,"waht url")
        loginSDK.keepLoggedIn(finalUrl,false, true)
      } else {
        setIsOpen(true);
      }
      // 未登录
    } else {
      const toUrl = `${process.env.GATSBY_LOGIN_DEFAULT}?enter=${type}`;
      window.gotinTrack.standaloneSendData({name: 'login.enter', params: {value: toUrl}})
      loginGotin(type)
    }
  };

  const toogleLangInfo = () => {
    setExpanded(!isExpanded);
  };
  const toogleUserInfo = (event) => {
    event.nativeEvent.stopImmediatePropagation();
    setisShowMenu(!isShowMenu);
  };
  const handleScroll = () => {
    if (ref.current) {
      setSticky(window.scrollY >= 28);
    }
  };
  useEffect(() => {
    document.addEventListener("click", (e) => {
      setisShowMenu(false);
    });
    window.addEventListener('scroll', handleScroll);

    async function Login() {
      try {
        const user = await handleLogin();
        if (user) {
          await getJumpProtoIme()
          const {nickname = '', firstName = '', lastName = '', uid = '' } = user.properties || {};
          window.gotinTrack.registerInfo({
            user_id: uid, // 用户ID
            user_type: 1
          })
          const {
            properties: { avatar },
          } = user;
          if (avatar) {
            setAvatar(avatar);
          }
          setisLoading(false);
        }
        setisLoading(false);
        if (isLoggedIn()) {
          setisLogin(false);
        }
      } catch (error) {
      }
    }
    Login();
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const handleMenuBar = (item) => {
    const { id, url } = item;
    switch (id) {
      case 5:
        loginSDK.logout()
        setisLogin(true);
        break;
      case 4:
        if (isMobile) {
          setIsOpen(true);
        } else {
          handleJump(url)
        }
        break;
      default:
        handleJump(url);
        break;
    }
  };

  const handleJump = (url) => {
    if (isBrowser()) {
      if (isNotIOS()) {
        loginSDK.keepLoggedIn(url, false, true)
      } else  {
        loginSDK.keepLoggedIn(url, false, false)
      }
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const refVideo = useRef();
  useEffect(() => {
    const {current} = refVideo;
    const track1 = () => {
      if(current.paused) {
        window.gotinTrack.trackSend({
          name: 'video.paused',
          type: 'paused'
        })
      } else {
        window.gotinTrack.trackSend({
          name: 'video.play',
          type: 'play'
        })
      }
    }
    const track2 = () => {
      const isFullscreenNow = document.webkitFullscreenElement !== null
      window.gotinTrack.trackSend({
        name: `video.full.${isFullscreenNow}`,
        type: 'fullscreen'
      })
    }
    current.addEventListener('play', track1, false)
    current.addEventListener('pause', track1, false)
    current.addEventListener('webkitfullscreenchange', track2, false)
    return () => {
      current.removeEventListener('play', track1, false)
      current.removeEventListener('paused', track1, false)
      current.removeEventListener('webkitfullscreenchange', track2, false)
    };
  }, []);

  useEffect(() => {
    async function getStatus () {
      try {
        const res = await getUserStatus();
        setisManage(res.status)
      } catch (error) {
        console.log(error);
      }
    }
    getStatus()
  }, [])

  return (
    <div className="text-white text-center bg-m-home hxl:bg-pc-home bg-no-repeat bg-100% ">
      <ThemeProvider theme={themeSnackbar}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isOpen}
          onClose={handleClose}
          key={"top" + "center"}
          autoHideDuration={1800}
        >
          <Alert
            icon={<Error fontSize="inherit" />}
            severity="error"
            color="warning"
          >
            请移步至PC端使用创作者中心
          </Alert>
        </Snackbar>
      </ThemeProvider>
      {/* <img src={Sbg} alt="" className="hidden hxl:block absolute w-58 right-0"/> */}
      {/* <div className=" "> */}
      {/* <img src={BgDot} alt="" className="hidden hxl:block absolute w-90 hxl:-right-28 top-1/2 " /> */}
      {/* </div> */}
      <div className={`flex justify-between p-3 hxl:px-7.5 hxl:py-4 fixed w-full ${isSticky ? 'backdrop-filter backdrop-blur-gotin bg-headerbg bg-opacity-70' : ''}`} style={{zIndex:1300}}  ref={ref}>
        {isSticky ? <Logo logo={alogo} /> : <Logo />}
        <div className="flex items-center relative">
          {!isLoading ? (
            <div className="flex items-center relative">
              <Button
                title={!isLogin&&isManage?"活动管理":"创建活动"}
                showAdd={!(!isLogin&&isManage)}
                data-track={JSON.stringify({type: ['click', 'exposure'], name: 'header.event'})}
                type="danger"
                onClick={() => handleBTnClick("register")}
              />
              {isLogin && !isLoading && (
                <Button
                  data-track={JSON.stringify({type: ['click', 'exposure'], name: 'header.login'})}
                  title="登录"
                  type="primary"
                  className="text-gray ml-3"
                  onClick={() => handleBTnClick("login")}
                />
              )}
              {!isLogin && !isLoading && (
                <div
                  className="h-10 w-10 rounded-full ml-3"
                  onClick={toogleUserInfo}
                >
                  <img
                    src={
                      avatar
                        ? avatar.indexOf("https") === -1
                          ? `${process.env.GATSBY_OSS_BASE_URL}/${avatar}`
                          : avatar
                        : "https://exhibitor.gotin.online/mail/website/avatar.png"
                    }
                    className="rounded-full w-10 h-10 cursor-pointer"
                    alt="avatar"
                  />
                </div>
              )}
            </div>
          ) : (
            <Stack direction="row" spacing={1}>
              <Skeleton variant="rectangular" width={100} height={40} />
              <Skeleton variant="circular" width={40} height={40} />
            </Stack>
          )}
          {isShowMenu && (
            <div className="absolute  hxl:right-auto top-12 w-30 hxl:w-34 z-10">
              <div className="pt-1 pb-1 bg-white shadow-hbase rounded-xl text-card text-sm">
                {menuList &&
                  menuList.map((item) => (
                    <div
                      data-track={JSON.stringify({type: ['click', 'exposure'], name: `header.${item.traceName}`})}
                      onClick={() => handleMenuBar(item)}
                      className={[
                        item.id === 4
                          ? "text-center border-1 border-gotin text-gred flex items-center justify-center"
                          : "flex justify-between items-center",
                        " m-2 h-8  cursor-pointer hxl:hover:bg-gray rounded-lg",
                      ].join("")}
                      key={item.id}
                    >
                      <div className="flex items-center">
                        {item.icon && (
                          <img
                            src={item.icon}
                            alt={item.name}
                            className="w-6 ml-2 mr-2"
                          />
                        )}
                        <span>{item.name}</span>
                      </div>
                      {item.children && (
                        <KeyboardArrowDownRoundedIcon
                          fontSize="medium"
                          color="#e1e1e1"
                          onClick={toogleLangInfo}
                        />
                      )}
                    </div>
                    // {item.children && isExpanded && (
                    //   <div className="">
                    //     {item.children &&
                    //       item.children.map((child) => (
                    //         <div className="p-2" key={child.id}>
                    //           <span>{child.name}</span>
                    //         </div>
                    //       ))}
                    //     )
                    //   </div>
                    // )}
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="hxl:ml-20 hxl:mr-20 mt-25.5 hxl:mt-29"
        data-sal="slide-up"
        data-sal-delay="60"
        data-sal-easing="ease"
      >
        <div className="hxl:flex hxl:justify-between hxl:max-w-screen-hxl hxl:mx-auto">
          <div className="flex flex-col items-center mt-10 hxl:items-start hxl:flex-shrink-0">
            <p className="text-xlg font-semibold hxl:text-pblg">
              有了，你的全方位云活动专家
            </p>
            <p className="mt-2 text-lg ml-8 mr-8 hxl:ml-0 hxl:text-xbase hxl:mt-4">
              1分钟创建你的高互动性云活动，拥有专属于你的活动营销平台
            </p>
            <Button
              title="免费试用"
              data-track={JSON.stringify({type: ['click', 'exposure'], name: 'center.free'})}
              className="mt-5 mb-5 hxl:mt-23.8"
              type="danger"
              size="large"
              onClick={() => handleBTnClick("register")}
            />
            <div className="h-40"></div>
          </div>
          <div className="relative ">
            <div className="flex justify-center items-center">
              <video
                ref={refVideo}
                autoPlay
                muted
                loop
                controls
                src="https://exhibitor.gotin.online/mail/website/website-video.mp4"
                poster="https://exhibitor.gotin.online/mail/website/poster.png"
                type="video/mp4"
                alt="gotin "
                className="h-huge absolute -top-40  rounded-base hxl:static hxl:h-110 hxl:w-140"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
