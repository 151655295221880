import React from "react"
import { Cases } from '../../mock/data'
const Case = () => {
  return <div className="mt-18 hxl:mt-13  mx-4.5">
    <div className="hidden hxl:flex hxl:justify-center">
      {Cases &&  Cases.map((item) =>  <img src={item.img} alt="" className={`hxl:w-30 hxl:h-16.5 ${item.id > 1 && item.id < 9  ? 'ml-4 hxl:ml-5': ''}` } key={item.id}/>)}
    </div>
    <div className="hxl:hidden">
      <div className="flex justify-between">
        { Cases &&  Cases.slice(0,4).map((item) =>  <img src={item.img} alt="" className="w-18 h-10" key={item.id}/>)}
      </div>
      <div className="flex justify-between mt-5">
        { Cases &&  Cases.slice(4,8).map((item) =>  <img src={item.img} alt="" className="w-18 h-10 " key={item.id}/>)}
      </div>
    </div>
  </div>
}


export default Case