import * as React from 'react'
import Container from './Container'
import GreatsCard from './UI/GreatsCard'
import {Hosts} from '../../mock/data'
import Tilt from 'react-parallax-tilt';
import { isMobile } from "react-device-detect";

const WordsFgreats = () => {
  const handleAdressJump = (e,to) => {
    if (!to) {
      e.preventDefault()
      return false
    }
  }
  return <Container title="大咖寄语" className="bg-base text-sblack flex flex-col items-center pb-15 hxl:pb-20 bg-g-main-bg hxl:bg-pc-g-main bg-no-repeat bg-100% ">
      <div data-track={JSON.stringify({type: 'exposure', name: 'remarks.frame'})} className='hxl:flex hxl:flex-wrap hxl:justify-between hxl:px-42.5 hxl:mt-15 hxl:max-w-screen-hxl hxl:mx-auto'>
        {Hosts.map((item, index) => <a data-track={JSON.stringify({type: 'click', name: `remarks.frame${index+1}`, params: {materials_id: item.id, external_url: item.address}})} href={item.address} className={`${(item.id  !== 1 && item.id !==4) ? (item.id === 2  || item.id === 5) ? 'hxl:mt-7.5':'hxl:mt-15':'hxl:mt-0'} mt-5 block`} key={item.id} target={item.address ? '_blank':'_self'} onClick={(e)=>handleAdressJump(e,item.address)} >
          <Tilt tiltMaxAngleX={10} tiltMaxAngleY={10} tiltEnable={!isMobile}>
            <GreatsCard logo={item.logo} description={item.desc} user={item.user} />
          </Tilt>
        </a>
      )}
      </div>
  </Container>
}

export default WordsFgreats
