import React, {useState, useEffect} from 'react'
import Logo from "../components/UI/Logo"
import Image from '../components/UI/Image'
import BackupOnline from "../components/UI/BackupOnline"
import BackupTop from "../components/UI/BackupTop"
import {Qrcode, Info} from '../../mock/data'
import { Link } from 'gatsby'
import { getImageAddress } from '../../utils'

const Footer = () => {
  const [isTop,SetIsTop] = useState(false)

  const hanleDomain = () => {
    const domain = window.location.hostname
    const isTop = process.env.GATSBY_APP_MODE !== 'prod' ? true : domain.indexOf('top') > -1
    SetIsTop(isTop)
  }

  useEffect(() => {
    hanleDomain()
  },[])
  
  return <div className='bg-black pt-10  hxl:pl-0 hxl:pt-13.5'>
    <div className="flex flex-col px-8 hxl:px-42.5 hxl:max-w-screen-hxl hxl:mx-auto">
      <Logo  className="w-20 hxl:w-28.5 "/>
      <div className="mt-5 hxl:flex hxl:justify-between hxl:mt-10">
        <div className='flex justify-between hxl:order-1 hxl:-ml-0'>
          {Info.map((item) => <div key={item.id} className={item.id === 2 ? 'ml-8 hxl:ml-10':''}>
            <p className="text-primary">{item.name}</p>
            {item.desc && item.desc.map((list) => <div key={list.label}>
              {item.id === 1 && <p className="text-white mt-1 hxl:mt-3" data-track={JSON.stringify({type: 'click', name: 'contact.company', params: {external_url: '/'}})}><Link to="/" >{list.label}{list.value}</Link></p>}
              {item.id === 2 && list.tag === 'mail' && <a className=" block text-white mt-1 hxl:mt-3" data-track={JSON.stringify({type: 'click', name: 'contact.us'})} href={`mailto:${list.value}`}>{list.label}： {list.value}</a>}
              {item.id === 2 && list.tag === 'phone' && <a className=" block text-white mt-1 hxl:mt-3" data-track={JSON.stringify({type: 'click', name: 'contact.us'})} >{list.label}： {list.value}</a>}
            </div>)}
          </div>)}
        </div>
        <div className='flex justify-evenly -ml-5 mt-6 items-center order-first hxl:mt-0 hxl:-ml-0'>
          {Qrcode.map((item, index) => <div key={item.id} className={item.id === 2 ? 'ml-8':''}>
            <Image data-track={JSON.stringify({type: 'exposure', name: `contact.code${index}`})} img={getImageAddress(item.imgPath)} alt={item.desc} />
            <p className="text-white text-sm text-center mt-3">{item.desc}</p>
          </div>)}
        </div>
      </div>
      {
        isTop ? <BackupTop /> : <BackupOnline />
      }
    </div>
  </div>
}

export default Footer
