import React from "react"
import { Helmet } from "react-helmet"

const SEO = () => {
  
  const siteMetadata = {
    title:'有了，你的全方位云活动专家 1分钟创建你的高互动性云活动，拥有专属于你的活动营销平台',
    url:'https://www.gotin.online',
    description:'有大科技致力于为客户提供最先进的云活动整体解决方案。旗下“有了”云活动平台是国际领先水平的SaaS平台，为主办方提供一站式的活动管理、宣发、执行、运营及效果分析服务。“有了”云活动平台以用户体验为中心，拥有舞台演讲、专家论坛、线上线下观众连麦、合作匹配、虚拟展商等多种在线交互场景。同时，为主办方提供详尽的原子级实时活动效果数据分析：对全球用户全流程跟踪数据，掌握大会目标用户画像，实时反馈活动亮点，了解宣传效率，精确到分秒的大会数据报告，全面分析大会效果以及合作情况',
    image:'src/images/favicon.png',
    keywords:'云活动, 有了云活动, 有了gotin, 有了, 线上活动, Gotin, gotin, 论坛, 线上论坛, 有大, 北京有大, 视频会议, 云展示, 云展, 云展会, 在线活动, 有了新世界, 活动策划, 策划活动, 活动执行, 活动数据, 在线互动, 线上互动, 直播, 活动直播, 展会直播, 峰会, 线上大型活动, 大型云活动'
  }

  return (
    <Helmet title={siteMetadata.title} >
      <meta name="keywords" content={siteMetadata.keywords}/>
      <meta name="description" content={siteMetadata.description} />
      <meta name="image" content={siteMetadata.image} />
      <meta name="full-screen" content="true"/>
      <meta name="x5-fullscreen" content="true"/>
      <meta name="360-fullscreen" content="true"/>
      <meta name="apple-mobile-web-app-capable" content="yes"/>
      <meta name="apple-mobile-web-app-status-bar-style" content="white"/>
      {siteMetadata.url && <meta property="og:url" content={siteMetadata.url} />}
      {siteMetadata.title && <meta property="og:title" content={siteMetadata.title} />}
      {siteMetadata.description && (
        <meta property="og:description" content={siteMetadata.description} />
      )}
      {siteMetadata.image && <meta property="og:image" content={siteMetadata.image} />}
      <link rel="shortcut icon" href={siteMetadata.url} />
      <link rel="canonical" href="'https://www.gotin.online/'" />
      <link rel="shortlink" href="'https://www.gotin.online/'" />
      <link rel="" href={siteMetadata.url} />
      <link rel="icon" href={siteMetadata.url} />
    </Helmet>
  )
}

export default SEO
