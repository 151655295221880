
import { getImageAddress } from '../utils'
import International from '../src/images/sence/international.svg'
import Goverment from '../src/images/sence/goverment.svg'
import Creating from '../src/images/sence/creating.svg'
import Createspeak from '../src/images/sence/createspeak.svg'
import OnlinePractice from '../src/images/sence/onlinePractice.svg'
import ProductPb from '../src/images/sence/productPb.svg'
import Exbition from '../src/images/sence/exbition.svg'
import Roundthis from '../src/images/sence/roundthis.svg'
import Zoomming from '../src/images/sence/zoom.svg'
import Shalong from '../src/images/sence/shalong.svg'
import Edu from '../src/images/sence/edu.svg'
import BusinessP from '../src/images/sence/businessP.svg'
import Pubcourse from '../src/images/sence/pubcourse.svg'
import KnowPay from '../src/images/sence/knowPay.svg'
import Instring from '../src/images/sence/instring.svg'

import IsocL from '../src/images/host/isoc.png'
import Gsima from '../src/images/host/gsma.jpg'
import CSNC from '../src/images/host/csnc.png'
import TshAiLo from '../src/images/host/tshai.png'
import Hicoolo from '../src/images/host/hicool.png'
import Ychina from '../src/images/host/ychina.png'

import Inhub from '../src/images/active/inhub.png'
import  CasePorsche from "../src/images/case/porsche.png"

const CaseAi = `${process.env.GATSBY_OSS_URL}/case/ai.png`
const CaseCise = `${process.env.GATSBY_OSS_URL}/case/CISE.png`
const CaseGsma = `${process.env.GATSBY_OSS_URL}/case/GSMA.png`
const CaseHarvard = `${process.env.GATSBY_OSS_URL}/case/harvard.png`
const CaseHytera = `${process.env.GATSBY_OSS_URL}/case/hytera.png`
const CaseIsof = `${process.env.GATSBY_OSS_URL}/case/isof.png`
const CaseUndp = `${process.env.GATSBY_OSS_URL}/case/UNDP.png`

const Live = `${process.env.GATSBY_OSS_URL}/feature/live.svg`
const Voice = `${process.env.GATSBY_OSS_URL}/feature/voice.svg`
const Round = `${process.env.GATSBY_OSS_URL}/feature/round.svg`
const Social = `${process.env.GATSBY_OSS_URL}/feature/social.svg`
const Business = `${process.env.GATSBY_OSS_URL}/feature/business.svg`
const Admin = `${process.env.GATSBY_OSS_URL}/feature/admin.svg`
const VIP = `${process.env.GATSBY_OSS_URL}/feature/vip.svg`
const Security = `${process.env.GATSBY_OSS_URL}/feature/security.svg`
const Svg3d = `${process.env.GATSBY_OSS_URL}/feature/3d.svg`

const Flive = `${process.env.GATSBY_OSS_URL}/feature/flive.png`
const Fmultiple = `${process.env.GATSBY_OSS_URL}/feature/fmultiple.png`
const Fround = `${process.env.GATSBY_OSS_URL}/feature/fround.png`
const Fone = `${process.env.GATSBY_OSS_URL}/feature/fone.png`
const fBusiness = `${process.env.GATSBY_OSS_URL}/feature/fbusiness.png`
const fVip = `${process.env.GATSBY_OSS_URL}/feature/fvip.png`
const fAdmin = `${process.env.GATSBY_OSS_URL}/feature/fadmin.png`
const fSecurity = `${process.env.GATSBY_OSS_URL}/feature/fsecurity.png`
const Png3d = `${process.env.GATSBY_OSS_URL}/feature/3d.png`

const Think = `${process.env.GATSBY_OSS_URL}/active/think.png`
const Origin = `${process.env.GATSBY_OSS_URL}/active/origin.png`
const Mvc = `${process.env.GATSBY_OSS_URL}/active/mvc.png`
const Ccw = `${process.env.GATSBY_OSS_URL}/active/ccw.png`

const Rocket = `${process.env.GATSBY_OSS_URL}/value/rocket.png`
const Start = `${process.env.GATSBY_OSS_URL}/value/star.png`
const Source = `${process.env.GATSBY_OSS_URL}/value/source.png`
const Report = `${process.env.GATSBY_OSS_URL}/value/report.png`

const Code = `${process.env.GATSBY_OSS_URL}/footer/qrcode.png`

const Cases = [
  {
    id:1,
    img:CaseGsma
  },
  {
    id:2,
    img:CasePorsche
  }, {
    id:3,
    img:CaseUndp
  }, {
    id:4,
    img:CaseHarvard
  }, {
    id:5,
    img:CaseAi
  }, {
    id:6,
    img:CaseIsof
  }, {
    id:7,
    img:CaseCise
  }, {
    id:8,
    img:CaseHytera
  }
]
//临时使用
const Values = [
  {
    id: 1,
    img: Rocket,
    title: '全场景覆盖',
    subTitle:'一键参会',
    description: '主办方和与会者在线互动平台，享受前所未有的云活动',
  },
  {
    id: 2,
    img: Start,
    title: '营销推广服务',
    subTitle:'个性化引流获客',
    description: '为主办方提供多重营销服务，源源不断的带来有效用户'
  },
  {
    id: 3,
    img: Source,
    title: '沉淀私域流量',
    subTitle:'助力业绩增长',
    description: '公域流量沉淀私域流量池，整合营销打造完整商业闭环'
  },
  {
    id: 4,
    img: Report,
    title: '线上线下联动',
    subTitle:'专属定制服务',
    description: '支持主办方多种业务需求，以需求为导向提供定制服务'
  }
]

const FeatureData = [
  {
    id: 1,
    img: Live,
    title: '互动式直播',
    description: '由活动发起人控制直播节奏，参会者报名成功后进入直播场次，观看时可与嘉宾实时互动',
    content: Flive,
    traceName: 'special.live'
  },
  {
    id: 2,
    img: Voice,
    title: '多人论坛',
    description: '活动发起人可指派主持人，主持人可选择参会者作为分享嘉宾上麦进行语音等操作，其他参会者作为观众观看及等待连麦',
    content: Fmultiple,
    traceName: 'special.forum'
  },
  {
    id: 3,
    img: Round,
    title: '小圆桌',
    description: '简洁版论坛，作为用户讨论区供参会者进行平等自由沟通交流',
    content: Fround,
    traceName: 'special.table'
  },
  {
    id: 4,
    img: Social,
    title: ' 1对1社交',
    description: '人工智能推荐为你匹配到可能感兴趣的参会者进行视频通话，打造沉浸式社交体验',
    content: Fone,
    traceName: 'special.1v1'
  },
  {
    id: 9,
    img: Svg3d,
    title: ' 3D化场景展示',
    description: '通过3D化场景搭建及数字人的技术手段，在虚拟空间中给予参会者更沉浸式的体验，以提升活动中的互动效率',
    content: Png3d,
    traceName: 'special.3D'
  },
  {
    id: 5,
    img: Business,
    title: '展商平台',
    description: '为展会主办方提供全方位展位线上管理服务，为参展商提供线上展览空间',
    content: fBusiness,
    traceName: 'adantage.firm'
  },
  {
    id: 6,
    img: Admin,
    title: '数据管理平台',
    description: '为企业提供多维度智能数据分析报告和智能决策方案，进而展开精细化运营',
    content: fAdmin,
    traceName: 'adantage.data'
  },
  {
    id: 7,
    img: VIP,
    title: '会员管理',
    description: '通过活动报名信息收集用户资料，轻松获取用户名单，助力企业构建私域流量阵地，为流量增长赋能',
    content: fVip,
    traceName: 'adantage.vip'
  },
  {
    id: 8,
    img: Security,
    title: '技术安全保障',
    description: '为企业提供高质量服务及7*24小时全天候技术安全保障，保障活动流畅，确认数据安全',
    content: fSecurity,
    traceName: 'adantage.skill'
  }
]

const Actives = [
  {
    id: 1,
    img: Inhub,
    title: '“In+hub”创新社区第二期加速营甄选日',
    time: '2022年5月28日',
    type: 0,
    tag: '3D',
    link: 'https://inhubsd.gotin.online/'
  },
  {
    id: 2,
    img: Think,
    title: 'Re：think 2021联合国开发计划署城市创新与零碳未来',
    time: '2021年12月13日',
    type: 0,
    tag: '3D',
    link: 'https://rethink2021undp-3d.gotin.online/'
  },
  {
    id: 3,
    img: Mvc,
    title: 'MWC中国分会场2022',
    time: '2022年2月28日',
    type: 0,
    link: 'https://mymwc.gotin.online/'
  },
  {
    id: 4,
    img: Ccw,
    title: '海能达 Hytera at CCW 2021',
    time: '2021年11月3-5日',
    type: 0,
    link: 'https://hyteraccwsummit.gotin.online'
  }
  // }, {
  //   id:5,
  //   img: Hp,
  //   title: '海能达hp项目',
  //   time: '2021.10.29',
  //   type: 0
  // }, {
  //   id:6,
  //   img: Cnc,
  //   title: 'The Gotin Team',
  //   time: '9月22日 GMT+8 12:00-14:00',
  //   type: 0
  // },
  // {
  //   id:7,
  //   img: Cnc,
  //   title: 'The Gotin Team',
  //   time: '9月22日 GMT+8 12:00-14:00',
  //   type: 0
  // },
  // {
  //   id:8,
  //   img: Cnc,
  //   title: 'The Gotin Team',
  //   time: '9月22日 GMT+8 12:00-14:00',
  //   type: 1
  // }
]


const Sences = [
  {
    id: 1,
    img: International,
    title: '国际峰会',
    description: '打造线上高质量峰会'
  },
  {
    id: 2,
    img: Goverment,
    title: '政府会议',
    description: '快速响应政府及协会需求'
  },
  {
    id: 3,
    img: Creating,
    title: '评选大赛',
    description: '报名预热+评比筛选+结果公示全流程服务'
  },
  {
    id: 4,
    img: Createspeak,
    title: '创业路演',
    description: '多种互动调动云会场气氛'
  },
  {
    id: 5,
    img: OnlinePractice,
    title: '线上训练营',
    description: '授课方式灵活变通不受局限'
  },
  {
    id: 6,
    img: ProductPb,
    title: '产品发布会',
    description: '打破传统线下活动参与人数限制'
  },
  {
    id: 7,
    img: Exbition,
    title: '展览会',
    description: '线上虚拟展全面提高展会效率'
  },
  {
    id: 8,
    img: Roundthis,
    title: '圆桌论坛',
    description: '主持人与嘉宾、观众间实时互动'
  },
  {
    id: 9,
    img: Zoomming,
    title: '线上会议',
    description: '提供极速快捷的远程会议体验'
  },
  {
    id: 10,
    img: Shalong,
    title: '沙龙',
    description: '小规模会议探讨交流'
  },
  {
    id: 11,
    img: Edu,
    title: '教育培训',
    description: '还原线下教学场景'
  },
  {
    id: 12,
    img: BusinessP,
    title: '企业培训',
    description: '帮助众多企业轻松在线培训'
  },
  {
    id: 13,
    img: Pubcourse,
    title: '公开课',
    description: '在线授课带来完整学习体验'
  },
  {
    id: 14,
    img: KnowPay,
    title: '知识付费服务',
    description: '课程随时随地展开'
  },
  {
    id: 15,
    img: Instring,
    title: '兴趣小组',
    description: '遇见兴趣相投的伙伴'
  },
]

const Hosts = [
  {
    id: 1,
    logo: IsocL,
    desc: '“有了gotin”满足了中国互联网大会的各类直播需求。对接需求时工作人员会以最快的响应速度，提供非常适合我们的解决方案，为线上活动和线下活动都提供了过硬的活动保障。',
    user: {
      avator: IsocL,
      name: '中国互联网大会',
    },
    address: 'https://mp.weixin.qq.com/s/KSgkC2qA4TMHqvsQM0lyfA'
  },
  {
    id: 2,
    logo: Gsima,
    desc: '感谢有了团队的辛苦耕耘，兢兢业业，耐心协助，终有收获。我们极力向各位主办方推荐有了团队的专业服务！',
    user: {
      avator: Gsima,
      name: 'GSMA',
    },
    address: 'https://mp.weixin.qq.com/s/pzcSpyBx3kq-Kjt29mwecQ'
  },
  {
    id: 3,
    logo: CSNC,
    desc: '我们与有了云活动进行过一次非常愉快的在线直播合作。该公司为我们精心定制的“全方位、全视角、多平台、多渠道”宣传推广方案，让我们获得了良好观众数据。在对接过程中，我们发现有了团队操作规范，流程紧密，这种工作方式我们非常满意，是我们最理想的在线会议合作伙伴！期待下次合作。',
    user: {
      avator: CSNC,
      name: "中国卫星导航年会",
    },
    address: ''
  },
  {
    id: 4,
    logo: TshAiLo,
    desc: '清华大学已经与“有了gotin”有过多次深度合作，面向全球大学生开展过多场创造营大赛。有了平台为活动量身定制的页面，工期短、交付快。后台直截了当，操作简便易上手，最重要的是，报名参与用户完全超出预期。这些都离不开“有了gotin”的专业与高效，期待未来可以共创出更多高效赋能的活动形式。',
    user: {
      avator: TshAiLo,
      name: 'U&AI青年创造营',
    },
    address: 'https://mp.weixin.qq.com/s?__biz=MzI2NjU4NzU0OA==&mid=2247484045&idx=1&sn=b0aa2d141439a8abc78430bb1e1890d6&chksm=ea8a9e7eddfd1768fecf9efd1fcb77c40d488bd60aa93573fe6879746b9e93f03cce27c92f99&token=970247368&lang=zh_CN#rd'
  },
  {
    id: 5,
    logo: Hicoolo,
    desc: '我们在寻找线上合作平台的时候发现了有大科技，这个团队的成员们有一种凝聚力，及其快速的响应且完成Hicool的各项需求，保障活动得以顺利展开，让Hicool得以收获来自客户与市场的高度赞誉及认可。',
    user: {
      avator: Hicoolo,
      name: 'Hicool全球创业者峰会',
    },
    address: ''
  },
  {
    id: 6,
    logo: Ychina,
    desc: '在有了gotin团队的大力支持下，奇绩创坛实现了线下路演到线上路演的全新转变，活动得以顺利开展。本次线上路演日没有受到地域和空间的局限，让更多投资人参与了活动，并实现了投资人与项目创始人的零距离互动与交流。',
    user: {
      avator: Ychina,
      name: '奇绩创坛',
    },
    address: 'https://mp.weixin.qq.com/s?__biz=MzI2NjU4NzU0OA==&mid=2247485709&idx=1&sn=2f0feff3ba1e82b17f01dc47590d9c96&chksm=ea8a95feddfd1ce881c81ca484c772a5834f1dcf6ff37cc46fc04e323045f77cbf0ac74de1f2&token=1603806169&lang=zh_CN#rd'
  }
]

const Info = [
  {
    id: 1,
    name: '公司',
    desc: [
      {
        label:'关于「有了」',
        value:''
      }
    ]
  },
  {
    id: 2,
    name: '联系我们',
    desc: [
      {
        tag:'mail',
        label:'邮箱',
        value:'info@gotin.online'
      },
      {
        tag:'phone',
        label: '电话',
        value: '18611755637'
      }
    ]
  }
]

const Qrcode = [
  {
    id: 1,
    img: Code,
    desc: '微信公众号',
    imgPath: 'footer/qrcode'
  },
  {
    id: 2,
    img: getImageAddress('assistant'),
    desc: '有了gotin助理',
    imgPath: 'assistant',
  }
]

export {
  Values,
  FeatureData,
  Actives,
  Sences,
  Hosts,
  Info,
  Qrcode,
  Cases
}
