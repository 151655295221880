import React, {useState} from "react"
import BaseCard from './BaseCard'
import UpArrow from '../../images/arrowup.svg'
import UpDown from '../../images/arrowdown.svg'



const FeatureCard = (props) => {
  const { img, title, description, currentId,id,isShowFeature,content, traceName } = props
  const isShow = currentId === id && isShowFeature
  return  <div className="ml-5 mr-5" data-sal="slide-up" data-sal-delay="80" data-sal-easing="ease">
      <BaseCard className="bg-shall flex items-center text-white cursor-pointer py-4 pl-4 hxl:flex-col hxl:w-55.5 hxl:h-70 hxl:px-5 hxl:py-11" >
        <img src={img} alt={title} className="h-lg w-16 hxl:w-18 hxl:h-18" />
        <div className="ml-3 hxl:text-center hxl:ml-0" >
          <p className="text-base font-semibold hxl:text-xbase hxl:mt-7 ">{title}</p>
          <p className="mt-1 text-lg hxl:mt-3 hxl:text-justify">{description}</p>
        </div>
        <img src={ isShow ?  UpArrow: UpDown} alt="展开" className="h-3.5 w-3.5 ml-3 mr-3 hxl:hidden" />
      </BaseCard>
      {isShow&&<div className="mt-8 mb-9 z-10 bg-f-main-bg bg-no-repeat bg-100%">
        <img data-track={JSON.stringify({type: 'exposure', name: `${traceName}`})} src={content} alt={title} className="transform-none"/>
      </div>}
    </div>
}
export default FeatureCard
