import * as React from "react"
import BaseCard from './BaseCard'

const ValueCard = (props) => {
  const { img, title,subTitle, description, id } = props

  return <div data-sal="slide-up" data-sal-delay="70" data-sal-easing="ease">
    <BaseCard className={`flex hxl:flex-col items-center justify-center  bg-white ml-5 mr-5 ${id !== 1 ? 'mt-10':'mt-5'} hxl:mt-0 hxl:h-99 hxl:w-55.5`} >
      <div className="h-28.5 w-28.5 hxl:h-40 hxl:w-40 flex justify-center items-center">
        <img src={img} alt={title} />
      </div>
      <div className="pl-5 flex-1 hxl:text-center hxl:pl-0 hxl:mt-7.5">
        <p className="text-base hxl:text-xbase font-semibold text-black">{title}</p>
        <p className="text-base hxl:text-xbase font-semibold text-black">{subTitle}</p>
        <p className="text-sbase hxl:text-lg text-gray mt-2 hxl:mt-4 text-justify hxl:text-center">{description}</p>
      </div>
    </BaseCard>
  </div>
}

export default ValueCard
