import * as React from 'react'
import classNames from 'classnames'

const Container = ({children,title, className}) => {
  const containerstyle = classNames('pt-15 hxl:pt-20',className)
  return <div className={containerstyle} >
    <p className='text-center text-xlg hxl:text-plg font-semibold' data-sal="slide-up" data-sal-delay="80" data-sal-easing="ease">{title}</p>
    {children}
  </div>
}

export default Container